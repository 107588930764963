import React, { Component } from 'react'
import { Link } from 'gatsby'
import { InputField } from 'components/FormFields'

import Loader from 'components/Loader'
import { ErrorMessage, FormHeader, StyledButton, StyledLink } from 'components/FormStyles'
import { FormBox, FormFlex, FormFlexInner, FormWrapper, FormWrapperBox } from 'components/FormLayout'
import { INPUT_WIDTH } from 'utils/formHelpers'
import { COLORS } from 'utils/styleHelpers'
import { authActions } from 'wrFirebase'

const INITIAL_STATE = {
	email: '',
	error: null,
	isLoading: false,
}

class ForgotPasswordForm extends Component {
	constructor(props) {
		super(props)
		this.state = { ...INITIAL_STATE }
	}

	onSubmit = async event => {
		this.setState({ isLoading: true })
		const { email } = this.state
		try {
			await authActions.doPasswordReset(email)
			this.setState({
				isLoading: false,
				error: {
					message: `If your email address is registered with us,
					instructions have been sent to reset your password`,
				},
				email: '',
			})
		} catch (error) {
			this.setState({ error })
		}

		// this.setState(() => ({ ...INITIAL_STATE }))
		event.preventDefault()
	}

	setEmailInputState = event => {
		this.setState({ email: event.target.value })
	}

	render() {
		const { email, error, isLoading } = this.state

		const isInvalid = email === ''

		return (
			<div>
				{isLoading ? (
					<Loader />
				) : (
					<FormWrapper>
						<FormWrapperBox>
							<FormHeader>Forgot Password</FormHeader>
							<form>
								<FormFlex>
									<FormBox>
										<InputField
											onChange={this.setEmailInputState}
											placeholder="Email Address"
											type="text"
											value={email}
											width={INPUT_WIDTH}
										/>
									</FormBox>
									<FormBox>
										<FormFlexInner>
											<StyledButton
												bg={COLORS.BLACK}
												disabled={isInvalid}
												onClick={this.onSubmit}
											>
												Reset My Password
											</StyledButton>
										</FormFlexInner>
									</FormBox>
									<FormBox>
										<StyledLink to="/login">Return to Login</StyledLink>
									</FormBox>
									{error && (
										<FormBox>
											<ErrorMessage>{error.message}</ErrorMessage>
										</FormBox>
									)}
								</FormFlex>
							</form>
						</FormWrapperBox>
					</FormWrapper>
				)}
			</div>
		)
	}
}

const ForgotPasswordLink = () => (
	<p>
		<Link to="/forgotpassword/">Forgot Password?</Link>
	</p>
)

export { ForgotPasswordForm, ForgotPasswordLink }
